@use "../variables.scss";

.swiper {
  padding-bottom: 40px;
}

.swiper-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-slide {
  height: auto;
  align-self: stretch;
}

.swiper-pb-0 {
  padding-bottom: 0;
}

.swiper-pagination-bullet {
  border-radius: 50%;
  border: 1px solid variables.$projectBlue;
  background-color: transparent;
  width: 1.2em;
  height: 1.2rem;
  padding: 0;
  opacity: 1;

  &-active {
    background-color: variables.$projectBlue;
  }
}

.swiper.bullet-white {
  .swiper-pagination-bullet {
    border-color: variables.$white;
    &-active {
      background-color: variables.$white;
    }
  }
}
