.text-placeholder {
  a {
    text-decoration: none;
    font-weight: normal;
    color: $projectBlue;
    border-bottom: 0.1rem solid $projectBlue;
    transition: color 300ms ease, border 300ms ease;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      color: $projectBlue;
      border-bottom: 0.2rem solid $projectBlue;
    }

    &:active {
      color: $projectBlue;
      border-bottom: 0.2rem solid $projectBlue;
    }
  }
}
