@media print {
  html {
    * {
      margin: auto !important;
    }
  }
  h1,
  h2 {
    margin-top: 5rem !important;
  }
  div {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  section {
    margin-bottom: 5rem !important;

    &[data-print-hide="true"] {
      display: none !important;
    }
  }
  footer {
    display: none !important;
  }
}
