@use "../variables.scss";

.form,
.form-uniform,
.embed {
  &.floated {
    .form-row {
      display: flex;
    }
  }

  .form-row {
    &--inline {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  abbr {
    &[title="required"] {
      color: variables.$projectRed;
    }
  }

  .form-row,
  .field-row {
    margin: 0 0 2.3rem;
    position: relative;

    &.disabled {
      label {
        pointer-events: none;
      }

      input,
      select,
      textarea {
        -webkit-text-fill-color: variables.$projectLightGrey; // Override iOS / Android font color change
        opacity: 1; // Override iOS opacity change affecting text & background color
        border-color: variables.$projectLightGrey;
        color: variables.$projectLightGrey;
        pointer-events: none;

        &::-webkit-input-placeholder {
          color: variables.$projectLightGrey;
        }

        &::-moz-placeholder {
          color: variables.$projectLightGrey;
        }

        &:-ms-input-placeholder {
          color: variables.$projectLightGrey;
        }

        &:-moz-placeholder {
          color: variables.$projectLightGrey;
        }
      }

      .select {
        .tick {
          border-top-color: variables.$projectLightGrey;
        }
      }
    }

    &.error {
      &[type="file"] {
        + .error {
          + label {
            border-color: variables.$projectDarkRed;
            color: variables.$projectDarkRed;

            &:active,
            &:focus {
              color: variables.$projectBlue;
              border-color: variables.$projectBlue;

              + .tick,
              + p + .tick {
                border-top-color: variables.$projectBlue;
              }
            }
          }
        }
      }

      .label {
        label {
          color: variables.$projectDarkRed;
        }
      }

      select {
        border-color: variables.$projectDarkRed;
        color: variables.$projectDarkRed;

        + p + .tick {
          margin-top: -0.9rem;
        }

        + .tick,
        + p + .tick {
          border-top-color: variables.$projectDarkRed;
        }
      }

      [type="text"],
      [type="password"],
      [type="email"] {
        border-color: variables.$projectRed;
      }

      .form-field {
        .message-wrapper {
          position: relative;
          bottom: auto;
          margin-top: 1.8rem;
          right: auto;
          max-width: none;
          width: 100%;

          .message {
            opacity: 1;
            position: relative;
            right: auto;
            top: auto;
            background-color: transparent;
            box-shadow: none;
            text-align: left;
            width: 100%;
            min-width: 100%;
            bottom: auto;
            font-size: 1.2rem;
            line-height: 2rem;
            padding-top: 0;
            color: variables.$projectDarkRed;

            &::after {
              display: none;
            }
          }

          .message.active {
            padding-left: 3rem;
          }

          &::after {
            display: none;
          }

          &::before {
            position: absolute;
            left: 0;
            background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
            transform: rotate(180deg);
          }

          &:hover {
            &::before {
              background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
            }
          }

          &:not(.inactive) {
            &:hover,
            &:focus {
              &::before {
                background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
                // transform: rotate(0);
              }
            }
          }
        }
      }

      .message-wrapper,
      .help-text {
        &::before {
          background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
          // transform: rotate(0);
        }

        &:hover {
          &::before {
            background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
            // transform: rotate(0);
          }
        }
      }
    }

    &.action {
      text-align: center;
    }

    .form-label {
      position: absolute;
      left: 1.6rem;
      top: 0;
      width: 100%;
      z-index: 1;
      transition: all 0.5s;
      height: 6rem;
      display: flex;
      align-items: center;
      pointer-events: none;
      padding: 0;

      label {
        transition: all 0.5s;
      }
    }

    .label {
      font-size: 1.5rem;
      padding: 0;

      label {
        color: variables.$projectDarkGrey;
        font-size: 1.6rem;
        line-height: 2.4rem;

        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }
      }
    }

    .form-field {
      min-height: 3rem;
      position: relative;
      padding: 0;

      .field {
        min-height: 100%;
        position: relative;

        &.no-min-height {
          min-height: 0;
        }

        .error {
          color: variables.$projectDarkRed;
          font-size: 1.3rem;
          margin: 0;
          padding-top: 0.2rem;
          clear: left;
        }
      }

      .message-wrapper {
        @include message-popup;
        bottom: 1.7rem;
        right: 1.3rem;
        padding-right: 0;

        &::before {
          background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237F8EB0%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
          position: relative;
          content: "";
          width: 2rem;
          height: 2rem;
          display: inline-block;
          right: 0.6rem;
        }

        .message {
          width: 26rem;
          right: 1.7rem;

          &::after {
            right: 0.2rem;
          }
        }
      }

      .group {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .list {
        .radio {
          display: block;
          margin-left: 0;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;

          label {
            display: inline-block;
          }
        }
      }
    }

    .required {
      float: right;
      color: variables.$projectDarkRed;
    }

    &.textarea {
      .form-label {
        position: relative;
      }
    }

    &.select-wrapper {
      .form-field {
        + .form-field {
          padding: 0 0 0 2rem;
        }
      }

      .form-label {
        position: relative;
        left: 0;
      }
    }

    &.focused:not(.select-wrapper) {
      .form-label {
        top: -1.2rem;

        label {
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }

      [type="tel"],
      [type="text"],
      [type="file"],
      [type="email"],
      [type="number"],
      [type="search"],
      [type="password"],
      .file-upload [type="file"] + label,
      .file-upload [type="file"] + .error + label,
      textarea {
        font-weight: 700;
      }
    }

    &:hover {
      .label {
        // font-weight: 700;
      }
    }
  }

  [type="tel"],
  [type="text"],
  [type="file"],
  [type="email"],
  [type="number"],
  [type="search"],
  [type="password"],
  [type="datetime-local"],
  [type="date"],
  .file-upload [type="file"] + label,
  .file-upload [type="file"] + .error + label,
  textarea {
    font-family: var(--lbs-base-font-family), sans-serif;
    transition: border-color 0.15s ease-in-out;
    border: 0.1rem solid variables.$projectWhite;
    border-radius: 0;
    appearance: (none);
    outline: none;
    color: variables.$projectBlue;
    width: 100%;
    box-shadow: none;
    padding: 2.9rem 1.6rem 0.5rem;
    background-color: variables.$projectWhite;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;

    &:active,
    &:focus {
      border-color: variables.$projectBlue;
      font-weight: 700;
    }

    &:focus {
      // setting the colour of the placeholder text // https://css-tricks.com/almanac/selectors/p/placeholder/
      &::-webkit-input-placeholder {
        transition: color 0.5s ease; // this doesn't require the use of the compass mixin as it's specifically for ::-webkit-input-placeholder
        color: variables.$projectLightGrey;
      }

      &::-moz-placeholder {
        transition: color 0.5s ease; // this doesn't require the use of the compass mixin as it's specifically for ::-moz-input-placeholder
        color: variables.$projectLightGrey;
      }

      &:-ms-input-placeholder {
        color: variables.$projectLightGrey;
      }

      &:-moz-placeholder {
        transition: color 0.5s ease; // this doesn't require the use of the compass mixin as it's specifically for :-moz-input-placeholder
        color: variables.$projectLightGrey;
      }
    }

    &::-webkit-input-placeholder {
      opacity: 0;
    }

    &::-moz-placeholder {
      box-shadow: none !important; // sass-lint:disable-line no-important - http://stackoverflow.com/questions/5939341/firefox-4-is-there-a-way-to-remove-the-red-border-in-a-required-form-input
      opacity: 0;
    }

    &:-ms-input-placeholder {
      opacity: 0;
    }

    &:-moz-placeholder {
      opacity: 0;
    }

    &:invalid {
      // including 'required' attribute on the <input> allows us to identify if it's invalid
      box-shadow: none;

      + .button-search {
        // if it's invalid, remove the ability to click on the button with pointer-events: none;
        pointer-events: none;
        color: variables.$projectLightGrey;
      }
    }

    &::-ms-clear {
      display: none;
    }
  }

  [type="datetime-local"] {
    padding: 1.6rem;
  }

  [type="date"] {
    padding: 1.6rem;
  }

  .file-upload {
    [type="file"] {
      width: 0.1rem;
      height: 0.1rem;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      + .error + label,
      + label {
        cursor: pointer;
        width: 18rem;
        color: variables.$projectGrey;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 1rem;

        &:focus,
        &:hover {
          color: rgba(variables.$projectGrey, 0.5);
        }
      }

      + label {
        padding: 0;
        background-color: transparent;
        border: 0.1rem solid variables.$projectBlue;
        display: flex;
        flex-direction: row;
        color: variables.$projectBlue;
        transition: all 0.3s;
        max-width: fit-content;

        span {
          &.icon-arrow {
            position: relative;
            width: 4.8rem;
            height: 4.8rem;
            flex-shrink: 0;

            svg {
              width: 2.2rem;
              height: 2.2rem;
              fill: variables.$projectBlue;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-90deg);
            }
          }

          &:last-child {
            display: block;
            border-left: 0.1rem solid variables.$projectBlue;
            font-size: 1.6rem;
            padding: 1.1rem 3.2rem;
            font-weight: bold;
          }
        }

        &:hover,
        &:focus {
          background-color: variables.$projectBlue;
          color: variables.$projectWhite;

          span {
            &.icon-arrow {
              svg {
                fill: variables.$projectWhite;
              }
            }

            &:last-child {
              border-left-color: variables.$projectWhite;
              padding-top: 0.9rem;
              padding-bottom: 1.3rem;
            }
          }
        }
      }

      &:focus {
        + label {
          background-color: variables.$projectBlue;
          color: variables.$projectWhite;

          span {
            &.icon-arrow {
              svg {
                fill: variables.$projectWhite;
              }
            }

            &:last-child {
              border-left-color: variables.$projectWhite;
              padding-top: 0.9rem;
              padding-bottom: 1.3rem;
            }
          }
        }
      }
    }

    .error {
      position: absolute;
      bottom: -1.6rem;
    }
  }

  .uploaded-file-name {
    margin-top: 2rem;
  }

  textarea {
    height: auto;
    min-height: 20rem;
    max-width: 100%;
    border-bottom: 0.1rem solid variables.$projectBlue;
  }

  .checkbox,
  .radio {
    position: relative;
    display: inline-block;
    margin-right: 5rem;
    margin-bottom: 1rem;

    &::after {
      display: block;
      content: attr(data-label);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      padding-left: 4rem;
      visibility: hidden;
      font-size: 1.5rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:focus {
      outline: none;
    }

    .tick {
      display: block;
      width: 2.2rem;
      height: 2.2rem;
      cursor: pointer;
      position: absolute;
      top: -0.1rem;
      left: -0.1rem;
      background-color: transparent;
      border: 0.1rem solid variables.$projectBlue;
      transition: (
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out
      );

      &::after {
        content: "";
        width: 1.3rem;
        height: 0.7rem;
        position: absolute;
        top: 0.5rem;
        left: 0.3rem;
        border: 0.3rem solid variables.$projectBlue;
        border-top: 0;
        border-right: 0;
        background: transparent;
        transition: (border-color 0.15s ease-in-out);
        opacity: 0;
        transform: rotate(-45deg);
      }
    }

    [type="checkbox"],
    [type="radio"] {
      position: absolute;
      left: 0.2rem;
      top: 0.2rem;
      opacity: 0;

      &:active,
      &:focus,
      &:hover {
        + .tick,
        + input + .tick {
          border-color: variables.$projectBlue;
          border-width: 0.2rem;
        }
      }

      &:focus,
      &:hover {
        label {
          font-weight: 700;
        }
      }

      &:focus {
        & ~ label {
          font-weight: 700;
          outline: auto;
        }
      }

      &:checked {
        + .tick,
        + input + .tick {
          background-color: variables.$projectBlue;
          border: 0.1rem solid variables.$projectBlue;

          &::after {
            border-color: variables.$projectWhite;
            opacity: 1;
          }
        }

        &:active,
        &:focus,
        &:hover {
          + .tick,
          + input + .tick {
            border-color: variables.$projectBlue;
          }
        }

        &[disabled] {
          + .tick,
          + input + .tick {
            background-color: variables.$projectLightGrey;
            border-color: variables.$projectLightGrey;

            &::after {
              opacity: 1;
            }
          }

          ~ label {
            color: variables.$projectGrey;
          }
        }
      }

      &[disabled] {
        &:active,
        &:focus,
        &:hover {
          + .tick,
          + input + .tick {
            + label {
              text-decoration: initial;
            }
          }
        }

        + .tick,
        + input + .tick {
          pointer-events: none;
          background-color: variables.$projectLightGrey;
          border-color: variables.$projectLightGrey;

          &::after {
            opacity: 0;
          }

          + label {
            cursor: default;
          }
        }
      }
    }

    label {
      display: block;
      position: relative;
      padding-left: 3rem;
      cursor: pointer;
      text-indent: 0;
      font-size: 1.5rem;

      h6 {
        margin-top: 0;
        font-size: 1.5rem;
      }

      a {
        font-weight: normal;
      }
    }
  }

  .radio {
    .tick {
      border-radius: 50%;
      background-color: transparent;
      border-color: variables.$projectBlue;

      &::after {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: #001e62;
        border: 0;
        top: 0;
        left: 0;
      }
    }

    [type="radio"] {
      &:checked {
        &:active,
        &:focus,
        &:hover {
          + .tick {
            &::after {
              opacity: 0.8;
            }
          }
        }

        + .tick {
          background-color: variables.$projectWhite;
          border: 0.1rem solid variables.$projectBlue;

          &::after {
            opacity: 1;
            background-color: variables.$projectBlue;
          }
        }

        &[disabled] {
          + .tick {
            background-color: variables.$projectLightGrey;
            border-color: variables.$projectLightGrey;

            &::after {
              opacity: 1;
              background-color: variables.$projectLightGrey;
            }
          }
        }
      }
    }
  }

  .search-form {
    position: relative;

    .button-search {
      font-family: var(--lbs-base-font-family), sans-serif;
      color: variables.$projectBlue;
      font-size: 1.5rem;
      background-color: variables.$projectWhite;
      position: absolute;
      height: 4.5rem;
      padding: 0.2rem 1rem 0;
      right: 0;
      top: 0;
      text-align: center;
      outline: none;
      cursor: pointer;
      border: 0.2rem solid variables.$projectGrey;
      text-decoration: none;
      transition: border-color 0.15s ease-in-out;

      &:focus,
      &:hover {
        background-color: variables.$projectLightGrey;
      }
    }

    .icon-magnifying-glass {
      transition: opacity 0.5s ease;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5rem;
      width: 2rem;
      height: 2rem;
      opacity: 0;

      path {
        fill: #baa0b3; // using this hex value as this is the Firefox default and it has a bug where it won't change the colour of the placeholder 'number' field
      }
    }

    .form-search {
      &:invalid {
        + .icon-magnifying-glass {
          opacity: 1;
        }

        + button {
          + .icon-magnifying-glass {
            opacity: 1;
          }
        }
      }

      &:focus {
        + .icon-magnifying-glass {
          opacity: 0.5;
        }

        + button {
          + .icon-magnifying-glass {
            opacity: 0.5;
          }
        }
      }

      &:valid {
        + .icon-magnifying-glass {
          opacity: 0;
        }

        + button {
          + .icon-magnifying-glass {
            opacity: 0;
          }
        }
      }
    }
  }

  .currency {
    input {
      padding-left: 3rem;

      + .symbol {
        &::before {
          color: variables.$projectBlue;
        }
      }

      &:active,
      &:focus {
        + .symbol {
          &::before {
            color: variables.$projectBlue;
          }
        }
      }
    }

    .symbol {
      font-family: var(--lbs-base-font-family), sans-serif;
      font-size: 2rem;

      &::before {
        transition: color 0.15s ease-in-out;
        position: absolute;
        left: 1.5rem;
        top: 0.85rem;
        color: #baa0b3; // using this hex value as this is the Firefox default and it has a bug where it won't change the colour of the placeholder 'number' field
      }

      &.gbp {
        &::before {
          content: "\00a3";
        }
      }

      &.eur {
        &::before {
          content: "\20AC";
        }
      }

      &.usd {
        &::before {
          content: "\0024";
        }
      }
    }
  }

  .disabled {
    .currency {
      input {
        + .symbol {
          &::before {
            color: variables.$projectLightGrey;
          }
        }
      }
    }
  }

  .error {
    .currency {
      input {
        border-color: variables.$projectDarkRed;
        color: variables.$projectDarkRed;

        + .symbol {
          &::before {
            color: variables.$projectDarkRed;
          }
        }

        &:active,
        &:focus {
          + .symbol {
            &::before {
              color: variables.$projectBlue;
            }
          }
        }
      }
    }
  }

  .select-wrapper {
    .form-label {
      height: 3rem;
    }

    label {
      position: relative;
      // top: 1rem;
    }

    &.is--active {
      label {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }

  .select {
    position: relative;

    .tick {
      transition: transform 0.1s ease;
      pointer-events: none;
      border-style: solid;
      position: absolute;
      right: 1.7rem;
      content: "";
      z-index: 0;
      height: 1.4rem;
      width: 1.4rem;
      bottom: 2.3rem;
      transform: rotate(90deg);
      transform-origin: center;
      border: 0;

      svg {
        fill: variables.$projectBlue;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    select {
      font-family: var(--lbs-base-font-family), sans-serif;
      background-color: variables.$projectWhite;
      -webkit-appearance: none;
      -moz-appearance: none;
      line-height: 1.15;
      border-radius: 0;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 2.2rem 3rem 1.9rem 1.8rem;
      outline: none;
      color: variables.$projectDarkGrey;
      width: 100%;
      border: 0.1rem solid transparent;

      &::-ms-expand {
        display: none;
      }

      &.open {
        + .tick {
          transform: rotate(270deg);
        }
      }

      optgroup {
        display: none;
      }

      &:focus {
        border-color: variables.$projectBlue;
      }
    }

    option {
      font-weight: 700;

      &:disabled {
        text-decoration: line-through;
      }

      &:not(:checked) {
        color: variables.$projectBlue;
        font-weight: 500;
      }
    }
  }

  [type="search"] {
    // remove the html5 webkit buttons from the search // http://stackoverflow.com/questions/9421551/how-do-i-remove-all-default-webkit-search-field-styling
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &:focus {
      + button {
        border-left-color: variables.$projectBlue;
      }
    }
  }

  input {
    &:-webkit-autofill {
      // change the colour of Chrome's autofill // http://stackoverflow.com/questions/22631943/change-font-color-of-autofill-input-field
      -webkit-box-shadow: 0 0 0 5rem variables.$projectWhite inset;
      -webkit-text-fill-color: variables.$projectBlue;

      &:focus {
        -webkit-box-shadow: 0 0 0 5rem variables.$projectWhite inset;
        -webkit-text-fill-color: variables.$projectBlue;
      }
    }
  }

  [type="number"] {
    // remove the spinner arrows from the number field // https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.chevron {
  position: relative;
  font-family: var(--lbs-base-font-family), sans-serif;
  font-size: 2rem;
  padding-right: 1rem;

  &.down {
    &::after {
      transform: translateY(-50%) rotate(135deg);
    }
  }

  &.left {
    padding-right: 0;
    padding-left: 1rem;

    &::after {
      right: auto;
      left: 0;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    background-color: transparent;
    right: 0;
    top: 55%;
    transform: translateY(-50%) rotate(45deg);
    border-top: 0.2rem;
    border-right: 0.2rem;
    border-bottom: 0;
    border-left: 0;
    border-color: variables.$projectBlue;
    border-style: solid;
  }

  &:active,
  &:focus,
  &:hover {
    &::after {
      border-color: variables.$projectBlue;
    }
  }

  &.disabled {
    cursor: default;
    color: variables.$projectGrey;

    &::after {
      border-color: variables.$projectGrey;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;

      &::after {
        border-color: variables.$projectGrey;
      }
    }
  }
}

form,
.form {
  .error-message {
    .validation-summary-valid {
      display: none;
    }

    ul {
      border-top: 0.1rem solid $projectLightGrey;
      border-bottom: 0.1rem solid $projectLightGrey;
      padding-top: 3rem;
      padding-bottom: 2.1rem;
      margin-bottom: 9.5rem;

      li {
        position: relative;
        font-size: 1.6rem;
        padding-left: 1.5rem;
        margin-bottom: 1.2rem;

        &::before {
          background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23c8103e%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
          position: relative;
          content: "";
          width: 2rem;
          height: 2rem;
          display: inline-block;
          left: -17px;
          top: 5px;
          transform: rotateX(180deg);
        }
      }
    }
  }
}

.grid-toggle-wrapper {
  // sass-lint:disable no-important
  display: none !important;
}

.CVorLinkedIn {
  padding-bottom: 3.4rem;
  padding-top: 3.9rem;
  margin-bottom: 3.9rem;
  margin-top: 3.4rem;
  border-bottom: 0.1rem solid #d7d2cb;
  border-top: 0.1rem solid #d7d2cb;
}

.remove-file {
  float: right;
  cursor: pointer;
}

.embed,
.form-uniform {
  &.component {
    h3 {
      margin-bottom: 0;
    }

    .checkbox {
      .tick {
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    input {
      &[type="submit"] {
        @include line-height(24, 16);
        background-color: $projectBlue;
        color: white;
        width: auto;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        font-size: 1.6rem;
        padding: 1.2rem 3.2rem;
        border: 2px solid transparent;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0;
        transition: all 0.3s;
        padding-left: 6rem;
        padding-right: 6rem;

        &:focus,
        &:hover {
          background-color: transparent;
          border: 2px solid $projectBlue;
          color: $projectBlue;

          &:not(.tertiary) {
            &:not(.download) {
              padding: 1rem 3.2rem 1.4rem;
              padding-left: 6rem;
              padding-right: 6rem;
            }
          }
        }
      }
    }

    .field-row {
      &.lbs-check {
        margin: 0;
      }

      label {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 0;
        transition: all 0.5s;
        font-size: 1.5rem;
        min-height: 3rem;
        padding-top: 0.8rem;

        + label {
          position: relative;
          height: 5rem;
          display: block;
        }
      }

      .fields-checkbox-radio,
      .checkbox {
        label {
          position: static;
        }

        .field-row {
          margin: 0;
        }
      }

      &.focused,
      &.focused-force {
        label {
          bottom: -2.75rem;
          font-size: 1.2rem;
        }
      }

      .uniform-checkbox {
        position: absolute;

        + label {
          display: block;
          position: relative;
          padding-left: 3rem;
          cursor: pointer;
          text-indent: 0;
          font-size: 1.5rem;
        }

        > span {
          // sass-lint:disable placeholder-in-extend
          @extend .checkbox;
        }
      }

      span {
        &.uniform-checkbox {
          // sass-lint:disable placeholder-in-extend
          @extend .checkbox;
          position: absolute !important;
        }
      }

      &.lbs-select {
        .tick {
          transition: transform 0.1s ease;
          pointer-events: none;
          border-style: solid;
          position: absolute;
          right: 1rem;
          content: "";
          z-index: 0;
          height: 0.9rem;
          width: 0.9rem;
          bottom: 1rem;
          transform: rotate(90deg);
          transform-origin: center;
          border: 0;

          svg {
            fill: $projectBlue;
            height: 0.9rem;
            width: 0.9rem;
          }
        }

        select {
          font-family: var(--lbs-base-font-family), sans-serif;
          background-color: $projectBone;
          -webkit-appearance: none;
          -moz-appearance: none;
          line-height: 1.15;
          border-radius: 0;
          font-size: 1.5rem;
          cursor: pointer;
          padding: 1rem 3rem 0.4rem 0;
          outline: none;
          color: $projectBlue;
          width: 100%;
          border: 0;
          border-bottom: 0.1rem solid $projectBlue;

          &::-ms-expand {
            display: none;
          }

          &.open {
            + .tick {
              transform: rotate(270deg);
            }
          }

          optgroup {
            display: none;
          }
        }
      }
    }

    .lbs-radio {
      > label {
        margin-bottom: 2rem;
        display: block;
      }

      label {
        position: static;
      }
    }

    .help-text {
      @include message-popup;

      .message {
        color: $projectBlue;
      }

      &::before {
        background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237988ac%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
      }

      &:hover {
        &::before {
          background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%237988ac%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
          transform: rotate(180deg);
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }

    .lbs-select {
      label {
        pointer-events: none;
      }
    }
  }
}

.embeddable-form,
.embed,
.form-uniform,
.form {
  &.component {
    h4 {
      font-size: 3rem;
    }

    .form-row,
    .field-row {
      // max-width: 45.3rem;

      &.full {
        max-width: none;
      }

      .form-field,
      .form-label,
      label {
        &:not(.base-true) {
          width: 100%;
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      .checkbox,
      .radio {
        min-height: 2rem;

        label {
          padding-left: 4rem;
          padding-top: 0.3rem;
        }

        &:hover {
          label {
            font-weight: bold;
          }
        }
      }

      &.radio-wrapper {
        .form-field {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .uniform-select {
        label {
          &.error {
            bottom: -3.6rem;
          }
        }
      }

      label {
        &.error {
          text-align: right;
          color: $projectRed;
        }
      }
    }

    .row-section {
      padding: 4.6rem 0 1rem;
      display: block;
      border-top: 1px solid $projectLightGrey;
      border-bottom: 1px solid $projectLightGrey;
      margin: 1.9rem 0 4.8rem;
    }

    .button-wrapper {
      margin-top: 2.5rem;

      p {
        max-width: 27rem;
      }
    }

    .btn-and-text-wrapper {
      display: flex;
      align-items: center;

      .sub-text {
        margin-left: 3rem;
        color: $projectBlue;
        font-size: 1.2rem;
      }
    }

    &.padded {
      padding-top: 9rem;
      padding-bottom: 9rem;

      &.blue {
        p {
          font-size: 1.6rem;
        }

        button {
          min-width: 22rem;
        }
      }
    }

    &.blue {
      background: $projectBlue;
      color: white;

      [type="tel"],
      [type="text"],
      [type="file"],
      [type="email"],
      [type="number"],
      [type="search"],
      [type="password"],
      .file-upload [type="file"] + label,
      .file-upload [type="file"] + .error + label,
      textarea {
        border-color: white;
        color: white;
      }

      .form-field {
        .message-wrapper {
          .message {
            color: $projectBlue;
          }

          &::before {
            background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237988ac%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
          }

          &:hover {
            &::before {
              background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%237988ac%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
              transform: rotate(180deg);
              background-repeat: no-repeat;
              background-size: 100%;
            }
          }
        }
      }
    }
  }
}

.react-datepicker-wrapper input {
  padding: 1.8rem 1.6rem 1.6rem !important;
}

.react-datepicker-wrapper ~ svg {
  position: absolute;
  right: 16px;
  top: 22px;
  width: 15px;
  height: 15px;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  left: -14px !important;
}

.gift-aid.form {
  padding-top: 4rem;
}

// COMPACT WEB
@media #{$compact-web} {
  .embeddable-form {
  }
}
// TABLET PORTRAIT
@media #{$tablet-portrait} {
  .embeddable-form {
  }
}
// MOBILE
@media #{$mobile} {
  // sass-lint:disable force-element-nesting
  .embeddable-form,
  .form.component {
    .btn-and-text-wrapper {
      display: block;

      .button-wrapper {
        margin-bottom: 1rem;
        max-width: none;

        > p {
          max-width: none;
        }
      }

      .sub-text {
        margin: 1rem 0 2rem;
        display: block;
      }
    }
  }

  .embeddable-form,
  .embed,
  .form-uniform,
  .form {
    &.component {
      .form-row,
      .field-row {
        label {
          &.error {
            text-align: right;
          }
        }
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .embeddable-form {
  }
}
// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .embeddable-form {
  }
}
