@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 300;
  src:
    local("☺"),
    url("/fonts/Montserrat-ExtraLight.woff2") format("woff2"),
    url("/fonts/Montserrat-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 400;
  src:
    local("☺"),
    url("/fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("/fonts/montserrat-light-webfont.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  src:
    local("☺"),
    url("/fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("/fonts/montserrat-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "GT-Sectra-Fine-Bold";
  font-display: swap;
  font-weight: 400;
  src:
    local("☺"),
    url("/fonts/GT-Sectra-Fine-Bold.woff2") format("woff2"),
    url("/fonts/GT-Sectra-Fine-Bold.woff") format("woff");
}

:root {
  --lbs-heading-font-family: "GT-Sectra-Fine-Bold";
  --lbs-base-font-family: "Montserrat";
}
