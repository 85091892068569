@import "../styles/variables";

.basicLightbox {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.01;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  z-index: 1000;
  will-change: opacity;
}

.basicLightbox--visible {
  opacity: 1;
}

.basicLightbox__placeholder {
  max-width: 100%;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 1;
  will-change: transform;

  &.faculty-profile-carousel {
    .close {
      top: 8rem;
      right: 4rem;
    }
  }
}

.basicLightbox__placeholder > iframe:first-child:last-child,
.basicLightbox__placeholder > img:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 95%;
  max-height: 95%;
}

.basicLightbox__placeholder > iframe:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  pointer-events: auto;
}

.basicLightbox__placeholder > img:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  width: auto;
  height: auto;
}

.basicLightbox__placeholder > [data-basicLightbox] {
  display: block !important;
}

.basicLightbox--iframe .basicLightbox__placeholder,
.basicLightbox--img .basicLightbox__placeholder,
.basicLightbox--video .basicLightbox__placeholder {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.basicLightbox--visible .basicLightbox__placeholder {
  -webkit-transform: scale(1);
  transform: scale(1);
}

[data-basicLightbox] {
  display: none !important;
}

.basicLightbox {
  &.basicLightbox--iframe {
    .basicLightbox__placeholder {
      pointer-events: unset;
    }
  }

  .close-lightbox {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
}

.basicLightbox.banner {
  background: $projectBone;

  .video-wrapper {
    left: 50%;
    max-height: 90vh;
    padding-top: 56.25%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 95%;
    max-height: 95%;
    pointer-events: all;
  }

  a.close {
    position: absolute;
    top: 4%;
    left: 6%;
    pointer-events: all;
    z-index: 2;

    svg {
      fill: black;
    }
  }

  &.basicLightbox--iframe {
    a {
      &.close {
        right: 3rem;
        left: auto;

        svg {
          fill: $projectBlue;
        }

        &:hover,
        &:focus {
          svg {
            fill: $projectInteractiveNavy;
          }
        }
      }
    }
  }
}

.basicLightbox.quote-lightbox {
  align-items: flex-start;

  .close {
    top: 9rem;

    svg {
      fill: #fff;
    }
  }
}

// MOBILE
@media #{$mobile} {
  .basicLightbox {
    &.banner {
      .video-wrapper {
        width: 100%;
      }

      iframe {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
