// Colours
$black: #000;
$white: #fff;
$grey: #b3b3b3;
$accessible-focus: #87b3ea;

// RUFUS brand
$bg-black: #181818;
$black-01: #2f2f2f;
$black-02: #33465c;
$grey-01: #f0f0f0;
$grey-02: #43444b;
$grey-03: #2f3038;
$grey-04: #979797;
$grey-05: #818181;
$grey-06: #d8d8d8;
$red: #e1231a;
$green: #347659;
$peach: #ff8f63;
$blue: #0e7cc0;
$blue-02: #33465c;
$light-blue: #d9e9ec;
$olive: #929421;
$lilac: #cfa6f5;
$yellow: #ffd781;
$cream: #f6f4ef;

$text-black: $grey-03;

//lbs
$fluidMax: 1140px;
$fluidMargin: 1; // Percentage

$compact-web: "all and (min-width:1024px) and (max-width:1430px)";
$tablet: "all and (min-width:400px) and (max-width:1024px)";
$tablet-portrait: "all and (min-width:768px) and (max-width:1023px) and (orientation: portrait)";
$mobile: "all and (min-width:128px) and (max-width:767px)";
$mobile-landscape: "all and (min-width:480px) and (max-width:768px)";
$mobile-portrait: "all and (max-width:480px)";
$ie11-styles: "screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";
$less-than-max-width: "all and (max-width:" + $fluidMax + ")";
$iphone-plus: "all and (min-width:414px) and (max-width:767px)";
$mobile-smallscreen: "all and (min-width:128px) and (max-width:400px)";

$projectBlack: #000000;
$projectWhite: #ffffff;
$projectBlue: #001e62;
$projectDarkBlue: #001440;
$projectRed: #c8103e;
$projectDarkRed: #c8103e;
$projectGrey: #6e6259;
$projectLightGrey: #d7d2cb;
$projectBone: #ebe8e5;
$projectDarkBone: #d7d2cb;
$projectInteractiveNavy: #7f8eb0;
$projectDarkGrey: #595959;
$projectSantaGray: #9b9baf;
$projectAthensGray: #e5e8ef;
$projectPineCone: #6e6259;
$projectAlto: #e0e0e0;
$projectBlueStone: #00685e;

// Font colour
$baseFontColour: #001e62;
$baseAnchorColour: #001e62;
